import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const DrLeeLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Lee"
    institution="Dignity Health"
    referralCode="1901LEE"
    physicianURL="https://www.dignityhealth.org/ourdoctors/1033298724-paul-lee"
  />
)

export default DrLeeLandingPage
